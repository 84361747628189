<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <h4 id="traffic" class="card-title mb-0">Informes de Visitas</h4>
            <div class="small text-muted">Administración de todos los informes de visitas</div>
          </b-col>
          <b-col>            
            <b-icon icon="question-circle-fill" class="h3 pull-right mt-2 module-help-information" v-b-popover.hover="help"></b-icon>            
          </b-col>            
        </b-row>
      </b-card>

      <b-row>
        <b-col lg="9">
          <b-row>
            <b-col lg="4">
              <Widget :configWidget="configWidget" reference="cant_reports_without_reading" :hasAccess="configWidget.elements.cantReportsWithoutReading" />
            </b-col>
            <b-col lg="4">
              <Widget :configWidget="configWidget" reference="cant_reports_last_months" :hasAccess="configWidget.elements.cantReportsLastMonths" />
            </b-col>            
          </b-row>
        </b-col>  
        <b-col lg="3">
          <b-list-group v-show="!isSeller">
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openReport()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Administración de informes</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Administración de todos los informes de visitas
              </p>
            </b-list-group-item>
          </b-list-group>                     
        
          <b-list-group v-show="!isSeller">
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openAnalysis()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Análisis de visitas</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Consultar análiss de visitas por vendedor y zona.
              </p>
            </b-list-group-item>
          </b-list-group>                             

          <b-list-group v-show="isSeller">
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openReportSeller()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Administración de mis informes</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>                  
                </small>
              </div>

              <p class="mb-1">
                Administración de todos mis informes de visitas
              </p>
            </b-list-group-item>
          </b-list-group> 

          <b-list-group v-show="isSeller">
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openAnalysisSeller()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Análisis de mis visitas</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Consultar análisis de mis visitas.
              </p>
            </b-list-group-item>
          </b-list-group>  

          <hr v-if="arr.PHPCustom.length">
          <b-list-group class="mt-2" v-if="arr.PHPCustom.length">
            <b-list-group-item v-for="item in arr.PHPCustom" :key="item.id" href="#" class="flex-column align-items-start" @click="openPHPCustom(item)">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">{{item.name}}</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                {{item.observations}}
              </p>
            </b-list-group-item>
          </b-list-group>
        </b-col>        
      </b-row>

    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper'
  import Widget from '@/components/inc/widget/widget'
  import ServicesPHP from '@/components/modules/users/php/staff/services'

  export default {
    components: {
      Widget
    },
    data: () => {
      return {
        access: {
          module_id: Modules.INFORME_VISITAS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'dashboard',
          elements: {}
        },
        configWidget: {
          module_id: Modules.INFORME_VISITAS,
          profile_id: Profiles.PERSONAL,
          elements: {             
            cantReportsLastMonths: true,  
            cantReportsWithoutReading: true,           
          }
        },
        primaryColor: '',  
        arr: {
          PHPCustom: []
        },              
        help: {
          title:'¿Cómo funciona este módulo?',
          content: () => { 
            return `Un usuario con perfil <b>STAFF</b>, puede visualizar todos los reportes generados
                    y cargarle reportes a cualquier cliente.
                    <br><br>
                    Un usuario con perfil <b>STAFF</b> y configurado como <b>VENDEDOR</b> 
                    puede visualizar solo sus reportes y cargarle reportes solo a sus clientes 
                    según las zona asignadas.`
          },            
          html: true
        }          
      }
    },
    created () {
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */

      /* Configurar permisos de los widgets */      
      this.configWidget.elements.cantReportsWithoutReading = Helper.hasAccessWidget(this.configWidget, 'cant_reports_without_reading')      
      this.configWidget.elements.cantReportsLastMonths = Helper.hasAccessWidget(this.configWidget, 'cant_reports_last_months')            
      /* Fin configuracion */
    },
    mounted() {
      this.setup()
      this.getPHPCustom()
    }, 
    computed: {
      isSeller(){
        if(Helper.getSeller()) {
          return true
        } else {
          return false
        }
      }
    },        
    methods: {
      setup () {        
        if(Session.getSession().settings) {    
          this.primaryColor = Session.getSession().settings.color_primary
                    
          this.createCSS()
        }
      },         
      createCSS() {
        var hoja = document.createElement('style')
        hoja.innerHTML = ""      
        hoja.innerHTML = hoja.innerHTML  + ".list-group-item-action:hover, .list-group-item-action:focus { background-color: " + this.primaryColor + "; }"

        document.body.appendChild(hoja);
      }, 
      openReport() {
        this.$router.push({ name: 'ReportsVisitsStaffCrudReport' })
      },   
      openReportSeller(){
        this.$router.push({ name: 'ReportsVisitsStaffCrudReportSeller' })
      },
      openAnalysis(){
        this.$router.push({ name: 'ReportsVisitsStaffQueryAnalysis' })
      },     
      openAnalysisSeller(){
        this.$router.push({ name: 'ReportsVisitsStaffQueryAnalysisSeller' })
      },

      getPHPCustom() {
        ServicesPHP.obtenerPHPByModule(Modules.INFORME_VISITAS)
        .then((response) => {
          this.arr.PHPCustom = response.data       
        })
      },
      openPHPCustom(item) {
        this.$router.push({ name: 'ViewStaffViewPHPCustom', params: {id: item.id} })
      }      
    }    
   
  }
</script>

<style>

</style>
